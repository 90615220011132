
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation, Getter } from "vuex-class";
import {
  ITradeTerm,
  ITradeTermCreateRequest,
  ITradeTermRequest,
  ITradeTermUpdateRequest
} from "@/types/trade_term";
import { TradeTermRequest } from "@/models/trade_term";

@Component({})
export default class extends Vue {
  //trade_term
  @Action("trade_term/adminGet")
  public getTradeTerm!: (trade_term_id: number) => void;

  @Action("trade_term/adminCreate")
  public create!: (params: ITradeTermCreateRequest) => boolean;

  @Action("trade_term/adminUpdate")
  public update!: (data: {
    trade_term_id: number;
    params: ITradeTermUpdateRequest;
  }) => boolean;

  @Action("trade_term/adminDelete")
  public deleteTradeTerm!: (trade_term_id: number) => boolean;

  @Getter("trade_term/single")
  public trade_term!: ITradeTerm;

  @Mutation("trade_term/clear")
  public clear!: () => void;

  //パラメータ定義
  public params: ITradeTermRequest = new TradeTermRequest();

  //変数定義
  public trade_term_id = 0;
  public submit_dialog = false;
  public destroy_dialog = false;
  public valid = true;
  public lazy = false;

  //ルール設定
  public rules = {
    terms: [(v: string) => !!v || "貿易条件は必須です"]
  };

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.trade_term_id = Number(this.$route.params.trade_term_id);
    this.clear();

    // 編集時
    if (this.trade_term_id) {
      await this.getTradeTerm(this.trade_term_id);

      this.setDefault();
    }

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  //--------
  // 更新時デフォルト値をセットする
  private setDefault() {
    this.params.createFromTradeTerm(this.trade_term);
  }

  // 登録確認画面
  public submitConfirm() {
    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  // 更新時と作成時で問合せ先が違う
  public async submit() {
    this.submit_dialog = false;
    let result = false;
    if (this.trade_term_id) {
      result = await this.update({
        trade_term_id: this.trade_term_id,
        params: this.params
      });
    } else {
      result = await this.create(this.params);
    }
    if (result) {
      this.$router.go(-1);
    }
  }

  //--------
  // 削除確認画面
  public destroyConfirm() {
    this.submit_dialog = false;
    this.destroy_dialog = true;
  }

  //--------
  // 削除実行
  public async destroy() {
    this.destroy_dialog = false;
    if (await this.deleteTradeTerm(this.trade_term_id)) {
      this.$router.push(`/admin/trade_term/list`);
    }
  }
}
